import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  // background: #1e1e1e;
  height: 100%;
  width: 100%;
`;

interface IProps {
  children: (params: { rect: DOMRect }) => JSX.Element;
}

export const Measure = ({ children }: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  return (
    <Container ref={ref}>
      {rect && children({rect})}
    </Container>
  )
}
