import { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as monaco from 'monaco-editor';
import { Measure } from './Measure';

const Container = styled.div<{
  width: number;
  height: number;
}>`
  flex: 1;
  min-width: 200px;
  min-height: 200px;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

interface IProps {
  code1: string;
  code2: string;
  editorDidMount: (ref: MutableRefObject<HTMLDivElement | null>) => void;
}

export const Diff = (props: IProps) => {
  return (
    <Measure>
      {({ rect }) => {
        return (
          <>
            <SubDiff {...props} width={rect.width} height={rect.height} />
          </>
        );
      }}
    </Measure>
  );
}
export const SubDiff = ({
  width,
  height,
  code1,
  code2,
  editorDidMount,
}: IProps & {
  width: number;
  height: number;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [editor, setEditor] = useState<monaco.editor.IStandaloneDiffEditor>();

  useEffect(() => {
    if (ref.current && !editor) {
      const originalModel = monaco.editor.createModel(code1.trim(), "python");
      const modifiedModel = monaco.editor.createModel(code2.trim(), "python");

      const _editor = monaco.editor.createDiffEditor(ref.current, {
        theme: 'vs-dark',
        selectOnLineNumbers: true,
        fontSize: 14,
        readOnly: true,
      });

      _editor.setModel({
        original: originalModel,
        modified: modifiedModel
      });

      setEditor(_editor);
      editorDidMount(ref);
    }
  }, [code1, code2, ref, editor, editorDidMount]);

  useEffect(() => {
    return () => {
      if (editor) {
        try {
        editor.dispose();
        const model = editor.getModel();
        if (model?.original) {
          model.original.dispose();
        }
        if (model?.modified) {
          model.modified.dispose();
        }
      } catch(err )
      {
        // console.log(err);
      }
      }
    };
  }, [editor])

  return (
    <Container width={width} height={height} ref={ref} />
  );
}
