import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from './Button';

import styled from 'styled-components';
import { CodeSnippet, Algorithm } from './CodeSnippet/CodeSnippet';
import { algorithms } from './algorithms/algorithms';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export interface Pyodide {
  runPython: (code: string) => string;
  runPythonAsync: (code: string) => Promise<string>;
  loadPackage: (params: Array<string>) => Promise<void>;
}

interface IProps {
  pyodide: Pyodide;
}

const compileCode = (code: string, algorithm: Algorithm) => `
import json

${code}

${algorithm.testCases}

def test(fn):
    results = []
    for test_case in test_cases:
        args, expectation = test_case
        print('expectation', expectation)
        for arg in args:
          print('arg', arg)
        result = fn(*args)
        print('result', result)
        results.append({
          'args': args,
          'result': result,
          'expectation': expectation,
          'success': result == expectation,
        })
    return json.dumps(results)
    

test(${algorithm.functionName})
`;

export type Run = (code: string) => {code?: string; error?: string };

export const TestRunner = ({ pyodide }: IProps) => {
  const [algorithmIndex, setAlgorithmIndex] = useState(0);

  const algorithm = useMemo<Algorithm>(() => algorithms[algorithmIndex], [algorithmIndex]);
  const run = useCallback<Run>((code) => {
    try {
      const compiledCode = compileCode(code, algorithm);
      console.log(compiledCode)
      return {
        code: pyodide.runPython(compiledCode),
        error: undefined,
      };
    } catch (err) {
      console.error(err);
      return {
        code: undefined,
        error: err,
      };
    }
  }, [algorithm, pyodide]);

  const loadNextAlgorithm = useCallback(() => {
    console.log('save a copy')
    save('');
    setAlgorithmIndex(prev => {
      if (prev === algorithms.length - 1) {
        return 0;
      }
      return prev + 1;
    });
  }, []);

  const select = useCallback((i: number) => {
    setAlgorithmIndex(i);
  }, []);

  const [localCode, setLocalCode] = useState<{ [index: string]: string}>(getLocalCode());

  const save = useCallback((code: string) => {
    setLocalCode(prev => ({
      ...prev,
      [algorithm.title] : code
    }));
  }, [algorithm]);

  const saveLocal = useThrottle((code: string) => {
    window.localStorage[LOCAL_KEY] = JSON.stringify(code);
  }, 1000);

  useEffect(() => {
    saveLocal(localCode);
  }, [localCode]);

  return (
    <Container>
      <CodeSnippet 
      run={run} 
      save={save}
      localCode={localCode}
      algorithm={algorithm} next={loadNextAlgorithm} total={algorithms.length} index={algorithmIndex} select={select} />
    </Container>
  );
}

const LOCAL_KEY = 'code-runs';

const getLocalCode = () => {
  try {
    return JSON.parse(window.localStorage[LOCAL_KEY]) || {};
  } catch(err) {}

  return {};
}

const useThrottle = (fn: any, amount: number) => {
  const [ready, setReady] = useState(true);
  const _fn = useCallback(fn, [fn]);
  const timer = useRef<number>();
  const nextFn = useRef<any>();
  const throttledFn = useCallback((params: any) => {
    if (ready) {
      setReady(false);
      _fn(params);

      timer.current = window.setTimeout(async () => {
        while (nextFn.current) {
          _fn(nextFn.current);
          nextFn.current = undefined;
          await wait(amount);
        }
        setReady(true);
        timer.current = undefined;
      }, amount);
    } else {
      nextFn.current = params;
    }

    const unload = () => {
      if (nextFn.current !== undefined) {
        _fn(nextFn.current);
      }
    };

    window.addEventListener("beforeunload", unload);
    
    return () => {
      window.clearTimeout(timer.current);
      window.addEventListener("beforeunload", unload);
    };
  }, [fn, amount]);
  return throttledFn
};

const wait = (dur: number) => new Promise(resolve => setTimeout(resolve, dur));
