import { Algorithm } from "../CodeSnippet/CodeSnippet";
import binarySearchCode from './binary_search/code.py';
import binarySearchTestCases from './binary_search/test_cases.py';

import bubbleSortCode from './bubble_sort/code.py';
import bubbleSortTestCases from './bubble_sort/test_cases.py';

import combinationsCode from './combinations/code.py';
import combinationsTestCases from './combinations/test_cases.py';

import fibonacciCode from './fibonacci/code.py'
import fibonacciTestCases from './fibonacci/test_cases.py'

import islandsCode from './islands/code.py'
import islandsTestCases from './islands/test_cases.py'

import quicksortCode from './quicksort/code.py'
import quicksortTestCases from './quicksort/test_cases.py'

import mergesortCode from './mergesort/code.py'
import mergesortTestCases from './mergesort/test_cases.py'

import bucketsortCode from './bucketsort/code.py'
import bucketsortTestCases from './bucketsort/test_cases.py'

import dfsPreOrderIterativeCode from './dfs_preorder_iterative/code.py';
import dfsPreOrderIterativeTestCases from './dfs_preorder_iterative/test_cases.py';

import dfsPreOrderCode from './dfs_preorder/code.py';
import dfsPreOrderTestCases from './dfs_preorder/test_cases.py';

import dfsInOrderCode from './dfs_inorder/code.py';
import dfsInOrderTestCases from './dfs_inorder/test_cases.py';

import dfsPostOrderCode from './dfs_postorder/code.py';
import dfsPostOrderTestCases from './dfs_postorder/test_cases.py';

import bfsCode from './bfs/code.py';
import bfsTestCases from './bfs/test_cases.py';

import dpCode from './dp/code.py';
import dpTestCases from './dp/test_cases.py';

import backtrackingCode from './backtracking/code.py';
import backtrackingTestCases from './backtracking/test_cases.py';

export const algorithms: Array<Algorithm> = [
  {
    title: "Binary Search",
    description: "Write an implementation of binary search.",
    functionName: 'binary_search',
    code: binarySearchCode,
    testCases: binarySearchTestCases,
    args: ['arr', 'target'],
  },
  {
    title: "Bubble Sort",
    description: "Bubble sort bubbles up each value",
    code: bubbleSortCode,
    functionName: 'bubble_sort',
    testCases: bubbleSortTestCases,
    args: ['arr'],
  },
  {
    title: 'Combinations',
    description: 'Not an algorithm per se, but something I need help with',
    code: combinationsCode,
    functionName: 'combinations',
    testCases: combinationsTestCases,
    args: ['arr', 'r'],
  },
  {
    title: 'Fibonacci',
    description: 'Just make sure to have this down',
    code: fibonacciCode,
    functionName: 'fibonacci',
    testCases: fibonacciTestCases,
    args: ['n'],
  },
  {
    title: 'Islands',
    description: 'Given a binary matrix of 0s and 1s (integers), return an integer representing the number of discrete islands. If a number borders another number on the north, south, east, or west side (but not diagonal), it is an island.',
    code: islandsCode,
    functionName: 'num_islands',
    testCases: islandsTestCases,
    args: ['arr'],
  },
  {
    title: 'Quicksort',
    description: `/* low  --> Starting index,  high  --> Ending index */
quickSort(arr[], low, high)
{
    if (low < high)
    {
        /* pi is partitioning index, arr[pi] is now
           at right place */
        pi = partition(arr, low, high);

        quickSort(arr, low, pi - 1);  // Before pi
        quickSort(arr, pi + 1, high); // After pi
    }
}`,
    code: quicksortCode,
    functionName: 'quicksort',
    testCases: quicksortTestCases,
    args: ['arr'],
  },
  {
    title: 'Mergesort',
    description: `MergeSort(arr[], l,  r)
If r > l
     1. Find the middle point to divide the array into two halves:  
             middle m = l+ (r-l)/2
     2. Call mergeSort for first half:   
             Call mergeSort(arr, l, m)
     3. Call mergeSort for second half:
             Call mergeSort(arr, m+1, r)
     4. Merge the two halves sorted in step 2 and 3:
             Call merge(arr, l, m, r)`,
    code: mergesortCode,
    functionName: 'mergesort',
    testCases: mergesortTestCases,
  args: ['arr'],
  },
  {
    title: 'Bucket Sort',
    description: `bucketSort(arr[], n)
1) Create n empty buckets (Or lists).
2) Do following for every array element arr[i].
.......a) Insert arr[i] into bucket[n*array[i]]
3) Sort individual buckets using insertion sort.
4) Concatenate all sorted buckets.`,
    code: bucketsortCode,
    functionName: 'bucketsort',
    testCases: bucketsortTestCases,
  args: ['arr'],
  },

  {
    "title": "DP",
    "description": "Solve the knapsack problem",
    functionName: 'knapsack',
    code: dpCode,
    testCases: dpTestCases,
    args: ['items', 'capacity'],
  },
  // {
  //   title: "DFS | Pre Order Iterative Traversal",
  //   description: "Pre order iterative traversal of DFS",
  //   code: dfsPreOrderIterativeCode,
  //   testCases: dfsPreOrderIterativeTestCases,
  //   functionName: 'dfs_preorder_iterative',
  //   args: ['root'],
  // },

  // {
  //   code: dfsPreOrderCode,
  //   testCases: dfsPreOrderTestCases,
  //   "title": "DFS | Pre Order Traversal",
  //   "description": "Pre order traversal of DFS",
  //   functionName: 'dfs_preorder',
  // },

  // {
  //   code: dfsInOrderCode,
  //   testCases: dfsInOrderTestCases,
  //   "title": "DFS | In Order Traversal",
  //   "description": "In order traversal of DFS",
  //   functionName: 'dfs_inorder',
  // },
  // {
  //   title: "DFS | Post Order Traversal",
  //   description: "Post order traversal of DFS",
  //   code: dfsPostOrderCode,
  //   testCases: dfsPostOrderTestCases,
  //   functionName: 'dfs_postorder',
  // },

  // {
  //   "title": "BFS | Level Order Traversal",
  //   "description": "",
  //   functionName: 'bfs',
  //   code: bfsCode,
  //   testCases: bfsTestCases,
  // },

  // {
  //   "title": "Backtracking",
  //   "description": "",
  //   functionName: 'backtracking',
  //   code: backtrackingCode,
  //   testCases: backtrackingTestCases,
  // },
  // // {
  // //   "title": "Union Find (Disjoint Set)",
  // //   "description": "",
  // //   functionName: 'union_find',
  // //   code: backtrackingCode,
  // //   testCases: backtrackingTestCases,
  // // },
  // // {
  // //   "title": "K-Way Merge",
  // //   "description": "",
  // //   functionName: 'kway_merge',
  // //   code: backtrackingCode,
  // //   testCases: backtrackingTestCases,
  // // },

];
// ].sort(() => .5 - Math.random());
