import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Algorithm } from './CodeSnippet';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Args = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 5px 0 0;
    border-right: 1px solid rgba(0,0,0,0.3);
    padding: 0 5px 0 0;
    max-width: 200px;
    overflow-x: scroll;
    position: relative;

    &:last-child {
      border-right: none;
    }
  }
`;

const TestCaseContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  overflow-x: scroll;
  position: relative;

  h2 {
    min-width: 60px;
    margin: 0 10px 0 0;
  }
`;

const TestCaseContainerBody = styled.div`
  display: flex;
  flex-direction: column;

`;

const Output = styled.div`
  display: flex;
  margin-bottom: 4px;
  max-width: 100%;
  overflow-x: scroll;

  pre {
    max-width: 360px;
    overflow-x: scroll;
    padding: 0;
    margin: 0;
  }

  label {
    font-weight: bold;
    margin-right: 5px;
  }
`;

interface IProps {
  algorithm: Algorithm;
  output: {code?: string; error?: string };
}

interface ITestCase {
  args: any;
  result: any;
  expectation: any;
  success: boolean;
}

export const TestCases = ({ algorithm, output }: IProps) => {
  const {
    error, code
  } = output;

  const testCases = useMemo<undefined | Array<ITestCase>>(() => {
    if (!error && code) {
      const _testCases = JSON.parse(code);
      return _testCases as Array<ITestCase>;
    }

    return undefined;
  }, [output]);

  if (error) {
    return (
      <Container>
        {JSON.stringify(error)}
      </Container>
    );
  }

  if (!testCases) {
    return (
      <Container />
    );
  }

  return (
    <Container>
      {testCases.map((testCase, i) => (
        <TestCase algorithm={algorithm} testCase={testCase} index={i} />
      ))}
    </Container>
  );
}

const TestCase = ({ algorithm, testCase, index }: { algorithm: Algorithm; index: number; testCase: ITestCase }) => {
  const {
    args,
    expectation,
    result,
    success
  } = testCase;

  return (
    <TestCaseContainer>
      <h2>{index + 1} {success ? '✅' : '❌'}</h2>
      <TestCaseContainerBody>
        <Output>
          <Args>
            {args.map((arg: any, i: number) => (
              <li key={i}><strong>{algorithm.args[i]}</strong>: {JSON.stringify(arg)}</li>
            ))}
          </Args>
        </Output>

        <Output>
        <label>Result:</label>
        {success ? (

          <pre>{JSON.stringify(result)} == {JSON.stringify(expectation)}</pre>
        ) : (
          <pre>{JSON.stringify(result)} != {JSON.stringify(expectation)}</pre>
        )}

        </Output>
      </TestCaseContainerBody>
    </TestCaseContainer>

  )
}
