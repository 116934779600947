import { ButtonHTMLAttributes } from 'react';

import styled from 'styled-components';
const BLUE = '#569cd6';

const ButtonContainer = styled.button`
  width: 200px;
  border-radius: 20px;
  border: none;
  background: ${BLUE};
  color: white;
  padding: 20px;
  font-size: 24px;
  cursor: pointer;
`;

export const Button = (props: ButtonHTMLAttributes<any>) => (
  <ButtonContainer {...props} />
);
