import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

const MAX_DOT_WIDTH = 16;

const DotContainer = styled.div`
  width: ${MAX_DOT_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BLUE = '#569cd6';

const LINE_HEIGHT = 2;
const Line = styled.div`
  height: ${LINE_HEIGHT}px;
  position: absolute;
  width: calc(100% - 12px);
  left: 50%;
  margin-left: calc(-50% + 6px);
  top: 50%;
  margin-top: -${LINE_HEIGHT / 2}px;
  background: #ddd;
`;

const Dot = styled.div<{ size: number; highlightSize: number; highlighted: boolean; }>`
  display: flex;
  z-index: 1;
  position: relative;
  border: 1px solid #ccc;
  transition-duration: 0.2s;
  cursor: pointer;
  ${({ size, highlightSize }) => `
  width: ${size}px;
  height: ${size}px;
  border-radius: ${size}px;

  &:hover {
  background: ${BLUE};
  width: ${highlightSize}px;
  height: ${highlightSize}px;
  border-radius: ${highlightSize}px;
  }
  `}
  ${({ highlighted, highlightSize: size }) => highlighted ? `
  background: ${BLUE};
  width: ${size}px;
  height: ${size}px;
  border-radius: ${size}px;
  ` : `
  background: #eee;
  `}

`;


interface IProps {
  total: number;
  index: number;
  select: (i: number) => void;
}

export const Dots = ({ total, index, select }: IProps) => {
  return (
    <Container>
      <Line />
      {Array(total).fill('').map((_, i) => (
        <DotContainer>
          <Dot highlightSize={MAX_DOT_WIDTH} size={12} highlighted={i === index} onClick={() => select(i)} />
        </DotContainer>
      ))}
    </Container>
  )

}
