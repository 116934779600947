import { useCallback, useEffect, useMemo, useState } from 'react';
import { GrRefresh } from 'react-icons/gr';


import styled from 'styled-components';
import { Button } from '../Button';
import { Diff } from '../Diff';
import { Editor } from '../Editor';
import { Run } from '../TestRunner';
import { Dots } from './Dots';
import { TestCases } from './TestCases';

const Time = styled.div<{ submitted: boolean }>`
  font-family: monospace;
  font-size: 34px;
  text-align: right;
  ${({ submitted }) => submitted ? `
    color: rgba(0,0,0,0.4);
  ` : ``}
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;

  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0 20px;
`;

const Sidebar = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  padding: 20px;
  width: 520px;
  overflow-x: scroll;
  position: relative;
`;

const EditorHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #1e1e1e;
  cursor: pointer;

  svg {
    background: white;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  h1 {
    margin-bottom: 5px;
    flex: 1;
    margin-top: 0;
  }
`;
export interface Algorithm {
  title: string;
  description: string;
  code: string;
  functionName: string;
  testCases: string;
  args: Array<string>;
}
const parseParams = (): {
  [index: string]: string;
} => {
  const initial: {
    [index: string]: string;
  } = {}
  const splitPairs = window.location.search.split('?').pop()?.split('&') || [];
  return splitPairs.reduce((obj, pair) => {
    const [key, val] = pair.split('=');
    return {
      ...obj,
      [key]: val,
    }
  }, initial);
}

const newLine = `
`;

const makeDefaultCode = ({ title, functionName, args }: Algorithm, localCode: { [index: string]: string} ) => {
  if (localCode[title]) {
    return localCode[title];
  }
  return `
def ${functionName}(${args?.join(', ') || ''}):
  ''' Write your code here '''
  pass
`.trim();
};

const pad = (_s: number) => {
  const s = `${_s}`;
  if (s.length >= 2) {
    return s;
  }
  return `0${s}`;
}


interface IProps {
  next: () => void;
  run: Run;
  algorithm: Algorithm;
  total: number;
  index: number;
  select: (i: number) => void;
  save: (code: string) => void;
  localCode: {
    [index: string]: string;
  }
}

export const CodeSnippet = ({ localCode, algorithm, run, next, total, index, select, save }: IProps) => {
  // const params = parseParams();
  const [loaded, setLoaded] = useState(false);
  const [code, setCode] = useState<string>(makeDefaultCode(algorithm, localCode));
  const [defaultCode, setDefaultCode] = useState<string>(code);
  const [submitted, setSubmitted] = useState(false);
  const [output, setOutput] = useState<{ code?: string; error?: string }>({});
  const [start, setStart] = useState(new Date());

  useEffect(() => {
    if (loaded) {
      setOutput({})
      setSubmitted(false);
      setElapsedSeconds(0);
      setStart(new Date());
      setDefaultCode(makeDefaultCode(algorithm, localCode));
    }
  }, [algorithm, loaded]);


  const refresh = useCallback(() => {
    console.log('refresh!');
    setDefaultCode(makeDefaultCode(algorithm, {}));
    setCode(makeDefaultCode(algorithm, {}));
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const runTests = useCallback(() => {
    setSubmitted(true);
    if (code) {
      setDefaultCode(code);
      setOutput(run(code));
    }
  }, [code, run]);

  const onChange = useCallback(setCode, [setCode]);

  useEffect(() => {
    save(code);
  }, [code]);

  const editorDidMount = useCallback((ref) => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const tryAgain = useCallback(() => {
    setSubmitted(false);
    setElapsedSeconds(0);
    setStart(new Date())
  }, []);

  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  useEffect(() => {
    if (submitted === false) {
      const timer = setInterval(() => {
        setElapsedSeconds(Math.floor(((new Date()).getTime() - start.getTime()) / 1000));
      }, 500);

      return () => {
        clearInterval(timer);
      }
    }
  }, [submitted, algorithm]);

  const formattedTime = useMemo(() => {
    const hours = Math.floor(elapsedSeconds / 60 / 60);
    let remainingSeconds = elapsedSeconds - (hours * 60 * 60);
    const minutes = Math.floor(remainingSeconds / 60);
    remainingSeconds = remainingSeconds - (minutes * 60)
    return [hours, minutes, remainingSeconds].map(pad).join(':')
  }, [elapsedSeconds]);

  return (
    <Container>
        <EditorContainer>
          <EditorHeader>
            <GrRefresh onClick={refresh} color="white" />
          </EditorHeader>
          {submitted ? (
            <Diff
              code1={code}
              code2={algorithm.code}
              editorDidMount={editorDidMount}
            />
          ) : (
            <Editor
              value={defaultCode}
              onChange={onChange}
              editorDidMount={editorDidMount}
            />
          )}
      </EditorContainer>
      <Sidebar>
        <Dots
          index={index}
          total={total}
          select={select}
        />
      <Info>
        <Title>
        <h1>{algorithm.title}</h1>
        <Time submitted={submitted}>{formattedTime}</Time>
        </Title>
        <p>{algorithm.description}</p>
      </Info>
      <TestCases output={output} algorithm={algorithm} />
      <ButtonContainer>
      {submitted ? (
        <>
        <Button disabled={!code} onClick={tryAgain}>Try again?</Button>
        <Button onClick={next}>Next</Button>
        </>
      ) : (
        <Button disabled={!code} onClick={runTests}>Run Tests</Button>
      )}

      </ButtonContainer>

      </Sidebar>
    </Container>
  );
}

