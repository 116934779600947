import { useCallback, useEffect, useState } from 'react';
import './App.css';
import { Pyodide, TestRunner } from './TestRunner';

declare global {
  interface Window { cache: any; loadPyodide: any; }
}

window['cache'] = {};

const fetchPyodide = async () => {
  if (!window.cache.pyodide) {
    window.cache.pyodide = await window.loadPyodide({ indexURL: "https://cdn.jsdelivr.net/pyodide/v0.18.0/full/" });
    await window.cache.pyodide.loadPackage(['pytest']);
  }
};

(async () => {
  fetchPyodide();
})();

const wait = (dur: number) => new Promise(resolve => setTimeout(resolve, dur));

function App() {
  const [pyodide, setPyodide] = useState<Pyodide>();

  const checkForPyodide = useCallback(async () => {
    if (!window['cache'].pyodide) {
      await wait(200);
      await checkForPyodide();
    }
    return window['cache'].pyodide;
  }, [])

  useEffect(() => {
    checkForPyodide().then(setPyodide)
  }, [checkForPyodide]);

  if (pyodide === undefined) {
    return (
      <div>
        Loading
      </div>
    );
  }

  return (
    <TestRunner pyodide={pyodide} />
  );
}

export default App;
